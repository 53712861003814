import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ServerAddressResponseWrapper} from "../models";
import {ApiClientService} from "../core/api-client.service";
import {CachePriority, HttpAction} from '../core/enums';
import {ApiClientOptions} from "../core/models";
import {GlobalConstants} from "../globals";
import {ServerAddressResponse} from "../models/server-address.response";
import {AppType} from '../enums/app-type-enum';
import {ApiAnonymousService} from 'app/core/api-anonymous-client.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
    appType: AppType;

    constructor(
        private _apiClient: ApiClientService,
        private _apiAnonymousService: ApiAnonymousService) {
    }

    getAppType() {
        return this.appType;
    }

    setAppType(type: AppType) {
        this.appType = type;
    }

    getServerAddressBySchoolCode(schoolCode: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<ServerAddressResponseWrapper> {
        let environmentsUrl = GlobalConstants.azureEnvironmentsUrl;
        let getURL = `LightSailApi/api/ServerAddress/GetServerAddressBySchoolCode?SchoolCode=${schoolCode}`;
        return this._apiClient.InvokeApi<ServerAddressResponseWrapper>(getURL, HttpAction.Get, null,
            new ApiClientOptions(true, cacheLevel, false, false, null, false, environmentsUrl));
    }

    getApiSettings(): Observable<ServerAddressResponse> {
        const getURL = `api/Environments/This`;
        return this._apiAnonymousService.get<ServerAddressResponse>(getURL);
    }

    getApiVersion() {
        const getURL = `api/Public/Version`;
        return this._apiAnonymousService.get<{ version: string }>(getURL);
    }
}
