import {HttpContext, HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {filter, forkJoin, from, merge, Observable, of} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {ClassesService} from '../../../../../../../src/app/api-client/classes.service';
import {EnvironmentService} from '../../../../../../../src/app/api-client/environment.service';
import {AppStateService} from '../../../../../../../src/app/app.service';
import {ClasslinkService} from '../../../../../../../src/app/core/classlink.service';
import {CachePriority} from '../../../../../../../src/app/core/enums';
import {Gg4lAuthService} from '../../../../../../../src/app/core/gg4l-auth.service';
import {MsalService} from '../../../../../../../src/app/core/msal.service';
import {UserType} from '../../../../../../../src/app/enums';
import {SsoProvider} from '../../../../../../../src/app/enums/sso-provider.enum';
import {AppStateConstants, GlobalConstants} from '../../../../../../../src/app/globals';
import {SessionStorageService} from '../../../../../../../src/app/localstorage/session-storage.service';
import {StringUtils} from '../../../../../../../src/app/shared/utils/string.utils';
import {LicenseType} from '../../enums/license-type';
import {IdsTokenInfo} from '../../models/ids-token-info';
import {LsMpClassResponse} from '../../models/ls-mp-class-response';
import {AuthService} from '../../services/auth.service';
import {CleverService} from '../../services/clever.service';
import {GoogleAuthService} from '../../services/google-auth.service';
import {
  ApiVersionActions,
  AuthActions,
  CheckTokenActions,
  ResetPasswordActions,
  ScreenSessionActions,
  SetupAccountActions,
  SetupUserSchoolActions,
  SignupAccountActions,
  UserClassesAction,
  UserSchoolsActions,
  UserSessionActions,
} from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import {SessionsService} from '../../../../../../../src/app/core/sessions.service';
import {ClassResponse, EndSession, ScreenSessionResponse, StartSession, StudentResponse, TeacherResponse} from '../../../../../../../src/app/models';
import {BrowserDetectionService} from '../../../../../../../src/app/core/browser-detection.service';
import moment from 'moment/moment';
import {DeviceService} from '../../../../../../../src/app/core/device.service';
import {ENV_CONFIG, EnvironmentConfig} from '../../../environment.config';
import {UUID} from 'angular2-uuid';
import {ScreenSessionService} from '../../../../../../../src/app/api-client/screen-session.service';
import {AdminType} from '../../../../../../../src/app/enums/admin-type.enum';
import {SchoolsService} from '../../../../../../../src/app/api-client/schools.service';
import {TeacherService} from '../../../../../../../src/app/api-client/teacher.service';
import {StudentService} from '../../../../../../../src/app/api-client/student.service';
import {MpAuthInfo} from '../../models/mp-auth-info';
import {UserService} from '../../services/user.service';
import {StudioSchoolResponse} from '../../models/studio-school-response';
import {ClassLiteResponseModel} from '../../models/class-lite-response-model';
import {withNgHttpCachingContext} from '../../ng-http-caching/ng-http-caching.service';
import {NycidpService} from '../../services/nycidp.service';
import {AcademicYearService} from '../../../../../../../src/app/api-client/academic-year.service';
import {SchoolAcademicYearResponse} from '../../../../../../../src/app/core/models/school-academic-year-response';

@Injectable()
export class AuthEffects {

  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticate),
      switchMap(action => {
        let context: HttpContext;
        // Only pass caching header if it has customerId, indicates that this is student
        if (action.customerId) {
          context = withNgHttpCachingContext({
            isCacheable: (req: HttpRequest<any>) => {
              return true;
            },
            getKey: (req: HttpRequest<any>) => {
              // Custom unique key to store the data
              return action.username + '@' + window.btoa(action.password) + '@' + req.method + '@' + req.urlWithParams;
            }
          });
        }
        return this.authService.authenticate(action.customerId, action.username, action.password, context).pipe(
          map(res => {
            return AuthActions.authenticateLSMP({token: res.access_token});
          }),
          catchError(err => of(AuthActions.authenticateFailure({error: err})))
        );
      })
    );
  });

  onlineAuthenticate$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.onlineAuthenticate),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectMPAccessToken),
      this.store$.select(AuthSelectors.selectMpCustomerId),
      this.store$.select(AuthSelectors.selectMpUsername),
    ]),
    map(([, accessToken, customerId, username]) => {
      const accessTokenInfo = StringUtils.parseJwt(accessToken);
      return AuthActions.authenticate({customerId, username, password: accessTokenInfo['password']});
    })
  ));

  authenticateLSMP$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateLSMP),
      switchMap(action => {
        try {
          const parsedToken = StringUtils.parseJwt(action.token);
          const idsTokenInfo: IdsTokenInfo = {
            userId: parsedToken['userId'],
            username: parsedToken['username'],
            role: parsedToken['role'],
            source: parsedToken['source'],
            password: parsedToken['password'],
            licensedProducts: parsedToken['licensedProducts'] ? JSON.parse(parsedToken['licensedProducts']) : [],
            customerId: parsedToken['customerId'],
            userType: this.getUserTypeByRole(parsedToken['role']),
            adminType: this.getAdminTypeByRole(parsedToken['role']),
            isProgramManager: parsedToken['role'] === 'programmanager'
          };

          // Do not check license info for super admin
          if (idsTokenInfo.userType === UserType.MPSuperAdmin) {
            SessionStorageService.Save(GlobalConstants.IDSToken, action.token);
            return [
              AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse: null, mpAuthResponse: null}),
              // Mark the auth workflow process completed for PLS
              AuthActions.authFlowCompleted()
            ];
            // Check for studio license
          } else if (
            idsTokenInfo.licensedProducts?.includes(LicenseType.StudioUSA) ||
            idsTokenInfo.licensedProducts?.includes(LicenseType.StudioInternational)) {
            // Proceed based on user type
            if (idsTokenInfo.userType === UserType.Student || idsTokenInfo.userType === UserType.Teacher) {
              let context: HttpContext;
              // Only pass caching header for student
              if (idsTokenInfo.userType === UserType.Student) {
                context = withNgHttpCachingContext({
                  isCacheable: (req: HttpRequest<any>) => {
                    return true;
                  },
                  getKey: (req: HttpRequest<any>) => {
                    // Custom unique key to store the data
                    return idsTokenInfo.username + '@' + req.method + '@' + req.urlWithParams;
                  }
                });
              }

              return forkJoin([
                // User username and password received from JWT token for LS authenticate if user role is student or teacher and is coming from MP temporarily
                this.authService.authenticateLS(action.token, null, null, context),
                this.authService.authenticateMP(action.token, moment().utcOffset()/60, context).pipe(
                  map(res => {
                    const parsedToken = StringUtils.parseJwt(res.access_token);
                    return <MpAuthInfo>{
                      ...res,
                      customerId: +parsedToken['customerId'],
                      customerName: parsedToken['customerName'],
                      userFirstName: parsedToken['userFirstName'],
                      userId: +parsedToken['userId'],
                      email: parsedToken['email'],
                      classIds: parsedToken['classes'] ? JSON.parse(parsedToken['classes']) : [],
                      closeTabOnLogout: parsedToken['closeTab'] && parsedToken['closeTab'] === 'True',
                      showStudentMaps: parsedToken['showStudentMaps'] && parsedToken['showStudentMaps'] === 'True',
                      allowImpersonate: parsedToken['allowImpersonate'] && parsedToken['allowImpersonate'] === 'True',
                    };
                  })
                )
              ]).pipe(
                switchMap(([lsAuthResponse, mpAuthResponse]) => {
                  // @TODO: Save all tokens into storage, either local or IDB for rehydration to avoid authenticate calls

                  // Store IDS token
                  SessionStorageService.Save(GlobalConstants.IDSToken, action.token);

                  // Store LS token and shard
                  SessionStorageService.Save(GlobalConstants.AuthKey, lsAuthResponse.access_token);
                  SessionStorageService.Save(GlobalConstants.ShardKey, lsAuthResponse.ShardKey);
                  // This is being used in event hub service
                  this.appStateService.set(AppStateConstants.online, true);

                  // Store MP token
                  SessionStorageService.Save(GlobalConstants.MPToken, mpAuthResponse.access_token);

                  // Google Tag Manager Reporting - START
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    'event': 'login',
                    'userId': idsTokenInfo.userId,
                    'accountId': mpAuthResponse.customerId,
                    'role': idsTokenInfo.role,
                    'email': mpAuthResponse.email,
                    'customer': mpAuthResponse.customerName,
                    'name': mpAuthResponse.userFirstName
                  });
                  // Google Tag Manager Reporting - END

                  // Here in the future when we allow more user types like district admins or partner admins
                  // who doesn't have classes then we can skip the loadUserClasses Action and call authenticateProcessCompleted() to proceed
                  if (idsTokenInfo.userType === UserType.Student) {
                    return [
                      AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse, mpAuthResponse}),
                      SetupUserSchoolActions.setupUserSchool({schoolId: lsAuthResponse.schoolId, isAllSelected: false}),
                    ];
                  } else if (
                    idsTokenInfo.adminType === AdminType.Unknown ||
                    idsTokenInfo.adminType === AdminType.SchoolAdmin ||
                    idsTokenInfo.adminType === AdminType.DistrictAdmin
                  ) {
                    return [
                      AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse, mpAuthResponse}),
                      UserSchoolsActions.loadUserSchools(),
                    ];
                  }
                })
              );
            } else {
              return of(AuthActions.authenticateFailure({error: new HttpErrorResponse({error: 'No Login Flow defined for this User.'})}));
            }
            // If source is mindplay and user only has one of MP licenses then redirect them to MP
          } else if (idsTokenInfo.source === 'Mindplay' &&
            (
              idsTokenInfo.licensedProducts?.includes(LicenseType.UniversalScreener) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.MVRC) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.FLRTOnly) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.MindPlayLiteracy) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.Mathematics) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.DyslexiaScreener) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.MindPlayLiteracyPlus)
            )) {
            // If MP user doesn't have studio license and have MVRC or UniversalScreener license then redirect them to MP
            if (idsTokenInfo.userType === UserType.Student) {
              window.location.href = `${this.environmentConfig.mpAccountUrl}?it=${action.token}`;
            } else {
              window.location.href = `${this.environmentConfig.mpManagerUrl}?it=${action.token}`;
            }
            return of(AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse: null, mpAuthResponse: null}));
            // If source is mindplay and user doesn't have license then stop workflow
          } else if (idsTokenInfo.source === 'Mindplay' && !idsTokenInfo.licensedProducts?.length) {
            return of(AuthActions.authenticateFailure({error: new HttpErrorResponse({error: 'No Licenses Available for this User.'})}));
            // If source is LS and user doesn't have studio license then redirect them to LS
          } else if (idsTokenInfo.source === 'LightSail' && !idsTokenInfo.licensedProducts?.includes(LicenseType.StudioUSA) &&
            !idsTokenInfo.licensedProducts?.includes(LicenseType.StudioInternational)) {
            // If LS user doesn't have studio license then redirect them to LS v10
            window.location.href = `${this.environmentConfig.lsV10Url}?it=${action.token}`;
            return of(AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse: null, mpAuthResponse: null}));
          } else {
            return of(AuthActions.authenticateFailure({error: new HttpErrorResponse({error: 'This Licenses type is not supported in app.'})}));
          }
        } catch (e) {
          throw e;
        }
      }),
      catchError((err, caught) => merge(caught, of(AuthActions.authenticateFailure({error: err}))))
    );
  });

  authenticateSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateSSO),
      switchMap(action => {
        switch (action.ssoProvider) {
          case SsoProvider.Google:
            return from(this.googleAuthService.authenticate()).pipe(
              map(res => ({token: res.access_token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.ActiveDirectory:
            return from(this.msalService.login()).pipe(
              map(res => ({token: res.token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.ClassLink:
            return this.classLinkService.authenticate().pipe(
              map(token => ({token: token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.Clever:
            if (action.token) {
              return this.cleverService.exchangeToken(action.token).pipe(
                map(token => ({token: token, ssoProvider: action.ssoProvider}))
              );
            } else {
              return this.cleverService.authenticate().pipe(
                map(token => ({token: token, ssoProvider: action.ssoProvider}))
              );
            }
          case SsoProvider.GlobalGridForLearning:
            return from(this.gg4lAuthService.authenticate()).pipe(
              map(token => ({token: token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.NYCIDP:
            return from(this.nycidpService.exchangeToken(action.token)).pipe(
              map(token => ({token: token, ssoProvider: action.ssoProvider}))
            );
        }
      }),
      switchMap(({token, ssoProvider}) => {
        return this.authService.authenticateViaSSO(token, ssoProvider).pipe(
          map(res => {
            return AuthActions.authenticateLSMP({token: res.access_token});
          }),
        );
      }),
      catchError((err, caught) => merge(caught, of(AuthActions.authenticateFailure({error: err}))))
    );
  });

  logoutMPManagerUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logoutMPManagerUser),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserType),
      this.store$.select(AuthSelectors.selectMPAccessToken)]),
    // Students don't have access to Manager, so don't make this call
    filter(([,userType,]) => userType !== UserType.Student),
    switchMap(([action, userType, token]) => {
      return this.authService.logoutMPManagerUser(token)
        .pipe(map(() => AuthActions.logoutMPManagerUserSuccess()))
    }),
    catchError((err, caught) => merge(caught, of(AuthActions.logoutMPManagerUserFailure({error: err}))))
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map((action) => {
      return UserSessionActions.endUserSession({logout: true});
    })
  ));

  loadUserSchools$ = createEffect(() => this.actions$.pipe(
    ofType(UserSchoolsActions.loadUserSchools),
    concatLatestFrom(action => [
      this.store$.select(AuthSelectors.selectDefaultSchoolId),
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectMpCustomerId),
      this.store$.select(AuthSelectors.selectAdminType),
    ]),
    switchMap(([action, defaultSchoolId, userId, mpCustomerId, adminType]) =>
      forkJoin([
        this.schoolsService.getSchoolsByUserId(userId, true, CachePriority.NoCache).pipe(
          switchMap(schools => forkJoin({
            schools: of(schools),
            academicYears: this.academicYearService.getSchoolsAcademicYears(schools.map(sch => sch.schoolId))
          }))
        ),
        this.userService.getSchoolsLiteByCustomerId(mpCustomerId)
      ]).pipe(
        switchMap(([{schools, academicYears}, mpSchoolsLite]) => {

          const mpSchoolsMap = mpSchoolsLite.reduce((acc, item) => ({...acc, [item.schoolName?.trim()?.toLowerCase()]: item.schoolId}), {});
          const now = new Date();
          const currentAcademicYears: SchoolAcademicYearResponse[] = academicYears.filter(ay => new Date(ay.startDate) <= now && new Date(ay.endDate) >= now);
          const academicYearMap = currentAcademicYears.reduce((acc, item) => ({...acc, [item.schoolId]: item}), {});

          // Populate Mp schoolId in school response
          const studioSchools: StudioSchoolResponse[] = schools.map(sch => ({
            ...sch,
            mpSchoolId: mpSchoolsMap[sch.userGroupOrganizationName?.trim()?.toLowerCase()] ?? null,
            academicYear: academicYearMap[sch.schoolId]
          }));
          // Only exclude default school if user has more than one school
          const studioSchoolsExcludingDefault = studioSchools.length > 1 ? studioSchools.filter(sch => sch.userGroupOrganizationName !== 'Default School') : studioSchools;

          const schoolsWithClasses = studioSchoolsExcludingDefault.filter(sch => sch.hasActiveClasses);

          // Check if there is already a schoolId in local storage for that user
          // If there is then in that case preselect that school otherwise select first school
          const selectedSchoolId = window.localStorage.getItem(`${userId}-${GlobalConstants.SchoolId}`);

          const selectedSchool = selectedSchoolId ?
            schoolsWithClasses.find(sch => sch.schoolId === selectedSchoolId) :
            schoolsWithClasses.find(sch => sch.schoolId === defaultSchoolId);

          const school = selectedSchool ?? schoolsWithClasses?.[0] ?? studioSchoolsExcludingDefault?.[0];
          return [
            UserSchoolsActions.loadUserSchoolsSuccess({schools: studioSchoolsExcludingDefault}),
            SetupUserSchoolActions.setupUserSchool({schoolId: school.schoolId, isAllSelected: adminType === AdminType.DistrictAdmin})
          ];
        }),
        catchError(error => of(UserSchoolsActions.loadUserSchoolsFailure({error})))
      )
    )
  ));

  setupUserSchool$ = createEffect(() => this.actions$.pipe(
    ofType(SetupUserSchoolActions.setupUserSchool),
    concatLatestFrom(action => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectUserType),
      this.store$.select(AuthSelectors.selectAdminType),
      this.store$.select(AuthSelectors.selectMpUsername),
      this.store$.select(AuthSelectors.selectStudentId),
      this.store$.select(AuthSelectors.selectTeacherId),
      this.store$.select(AuthSelectors.selectUserSchoolForSchoolId(action?.schoolId))
    ]),
    switchMap(([action, userId, userType, adminType, mpUsername, studentId, teacherId, selectedSchool]) => {
      // Storing schoolId in local storage so it can be pre-set during login in case of browser refresh/re-login
      window.localStorage.setItem(`${userId}-${GlobalConstants.SchoolId}`, action.schoolId);

      let context: HttpContext;
      // Only pass caching header for student
      if (userType === UserType.Student) {
        context = withNgHttpCachingContext({
          isCacheable: (req: HttpRequest<any>) => {
            return true;
          },
          getKey: (req: HttpRequest<any>) => {
            // Custom unique key to store the data
            return mpUsername + '@' + req.method + '@' + req.urlWithParams;
          }
        });
      }

      return forkJoin([
        // mpSchoolId is not available for student, in the future, that can be added to mp auth token for student if needed
        selectedSchool ?
          of(selectedSchool) :
          this.schoolsService.Get(action.schoolId, CachePriority.NoCache, context).pipe(map(res => ({...res, mpSchoolId: null, academicYear: null}))),
        this.schoolsService.GetSchoolMetaData(action.schoolId, adminType === AdminType.DistrictAdmin, CachePriority.NoCache, context)
      ]).pipe(
        switchMap(([school, metaData]) => {
          const id = userType === UserType.Student ? studentId : teacherId;

          return [
            SetupUserSchoolActions.setupUserSchoolSuccess({school, metaData, isAllSelected: action.isAllSelected}),
            UserClassesAction.loadUserClasses({id, userType, adminType, schoolId: action.schoolId, mpSchoolId: school.mpSchoolId, preSelectMPClassId: action.preSelectMPClassId})
          ];
        }),
        catchError(error => of(SetupUserSchoolActions.setupUserSchoolFailure({error})))
      );
    })
  ));

  loadUserClasses$ = createEffect(() => this.actions$.pipe(
    ofType(UserClassesAction.loadUserClasses),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectMpClassIds),
      this.store$.select(AuthSelectors.selectMpUsername)
    ]),
    switchMap(([action, mpClassIds, mpUsername]) => {
      let observable: Observable<[ClassResponse[], ClassLiteResponseModel[]]>;
      if (action.userType === UserType.Student) {
        const context = withNgHttpCachingContext({
          isCacheable: (req: HttpRequest<any>) => {
            return true;
          },
          getKey: (req: HttpRequest<any>) => {
            // Custom unique key to store the data
            return mpUsername + '@' + req.method + '@' + req.urlWithParams;
          }
        });

        observable = forkJoin([
          this.classesService.GetClassesByStudent(action.id, CachePriority.NoCache, context),
          mpClassIds?.length ? this.userService.getClassesByClassIds(mpClassIds, context) : of([])
        ]);
      } else {
        observable = forkJoin([
          this.schoolsService.GetClassesInSchool(action.schoolId, action.adminType === AdminType.DistrictAdmin, CachePriority.NoCache),
          action.mpSchoolId ? this.userService.getClassesBySchoolIds([action.mpSchoolId]) : of([])
        ]);
      }

      return observable.pipe(
        map(([classes, mpClasses]) => {
          if (!classes?.length) {
            throw new HttpErrorResponse({error: 'No active classes in selected school.'});
          }

          return classes
            .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
            .map<LsMpClassResponse>((lsCls) => {
              const mpClassId: number =  mpClasses.find((mpCls) => mpCls.lsClassId === lsCls.classId)?.classId ?? NaN;
              return {
                ...lsCls,
                mpIdAtSource: mpClassId,
              };
            });
        }),
        switchMap(classes => {
          const classIds = classes.map(cls => cls.classId);
          return forkJoin({
            classes: of(classes),
            adminType: of(action.adminType),
            preSelectMPClassId: of(action.preSelectMPClassId),
            classStudentsMap: action.userType === UserType.Teacher ?
              this.studentService.getStudentsBySchoolAndClasses(action.schoolId, classIds).pipe(map(students => {
                let classStudentsMap: Record<string, StudentResponse[]> = {};
                students.map(({classIds, ...student}) => {
                  student.schoolId = action.schoolId;
                  student.fullName = `${student.firstName} ${student.lastName}`;
                  classIds.map(classId => {
                    classStudentsMap = {...classStudentsMap, [classId]: [...(classStudentsMap[classId] || []), student]};
                  });
                });

                // Sort the students within each class by fullName
                Object.keys(classStudentsMap).forEach(classId => {
                  classStudentsMap[classId].sort((a, b) =>
                    (a.fullName ?? '').toLowerCase().localeCompare((b.fullName ?? '').toLowerCase()));
                });

                return classStudentsMap;
              })) :
              of(null),
            classTeachersMap: action.userType === UserType.Teacher ?
              this.teacherService.getTeachersBySchoolAndClasses(action.schoolId, classIds).pipe(map(teachers => {
                let classTeachersMap: Record<string, TeacherResponse[]> = {};
                teachers.map(({classIds, ...teacher}) => {
                  teacher.fullName = `${teacher.firstName} ${teacher.lastName}`;
                  classIds.map(classId => {
                    classTeachersMap = {...classTeachersMap, [classId]: [...(classTeachersMap[classId] || []), teacher]};
                  });
                });

                // Sort the Teachers within each class by fullName
                Object.keys(classTeachersMap).forEach(classId => {
                  classTeachersMap[classId].sort((a, b) =>
                    (a.fullName ?? '').toLowerCase().localeCompare((b.fullName ?? '').toLowerCase()));
                });

                return classTeachersMap;
              })) :
              of(null)
          });
        })
      );
    }),
    switchMap((userClassesData: { classes: LsMpClassResponse[], adminType: AdminType, preSelectMPClassId: number, classStudentsMap: Record<string, StudentResponse[]> | null, classTeachersMap: Record<string, TeacherResponse[]> | null }) => {
      // Check if there is already a classId in session storage or passed from action
      // If there is then its a reload procedure, in that case preselect that class and mark the flow as complete
      const selectedClassId = SessionStorageService.Get(GlobalConstants.ClassId);
      const selectedMpClassId = userClassesData.preSelectMPClassId;

      // Class lookup
      let selectedLSClass: LsMpClassResponse;
      let selectedMPClass: LsMpClassResponse;

      if (selectedMpClassId) {
        selectedMPClass = userClassesData.classes.find(cls => cls.mpIdAtSource === selectedMpClassId);
      }
      if (selectedClassId) {
        selectedLSClass = userClassesData.classes.find(cls => cls.classId === selectedClassId);
      }

      // selectedMpClass takes preference
      const selectedClass = selectedMPClass ?? selectedLSClass ?? userClassesData.classes[0];

      // If action had specify clasId (Dist Admin Dash) or if user is either school admin or district admin then do not need to stop flow and let login component select a class, rather select all classes
      if (selectedClassId ||
        selectedMpClassId ||
        userClassesData.adminType === AdminType.DistrictAdmin ||
        userClassesData.adminType === AdminType.SchoolAdmin
      ) {
        return [
          UserClassesAction.loadUserClassesSuccess({userClassesData}),
          UserClassesAction.selectUserClass({selectedClass, isAllSelected: !selectedMpClassId && (userClassesData.adminType === AdminType.DistrictAdmin || userClassesData.adminType === AdminType.SchoolAdmin)}),
          AuthActions.authFlowCompleted(),
        ];
        // This will stop the login flow and control goes back to login component to select a class
      } else {
        return [
          UserClassesAction.loadUserClassesSuccess({userClassesData}),
        ];
      }
    }),
    catchError((error, caught) => merge(caught, of(UserClassesAction.loadUserClassesFailure({error}))))
  ));

  selectUserClass$ = createEffect(() => this.actions$.pipe(
    ofType(UserClassesAction.selectUserClass),
    switchMap(action => {
      // Storing classId in session storage so it can be recovered in case of browser refresh
      SessionStorageService.Save(GlobalConstants.ClassId, action.selectedClass.classId);

      return [
        // End Previous Login Session
        UserSessionActions.endUserSession({logout: false}),
        // Start New Login Session
        UserSessionActions.startUserSession({userGroupId: action.selectedClass.userGroupId})
      ];
    })
  ));

  startUserSession$ = createEffect(() => this.actions$.pipe(
    ofType(UserSessionActions.startUserSession),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectUTCOffset),
    ]),
    switchMap(([action, userId, utcOffset]) => {
      const now = moment().utcOffset(utcOffset, false); //get the utcOffset fixed date
      const startSession: StartSession = {
        isOffline: false,
        offlineSessionId: UUID.UUID(),
        userId: userId,
        userGroupId: action.userGroupId,
        startTime: now.toISOString(),
        deviceType: this.deviceService.getDeviceType(),
        deviceVersion: JSON.stringify({
          'platform': BrowserDetectionService.getApplicationPlatform(),
          'browserVersion': BrowserDetectionService.getBrowserVersion()
        }),
        applicationPlatform: GlobalConstants.ApplicationPlatform,
        applicationBuildType: GlobalConstants.ApplicationBuildType,
        lightSailVersion: this.environmentConfig.version,
        isOutsideSchoolHours: false, //TODO: remove hardcoded value when outside school hour feature implement
        isDaylightSaving: false, //TODO: remove hardcoded value when outside school hour feature implement
        utcOffset: utcOffset ? utcOffset : 0,
        localTime: now.format('HH:mm:ss')
      };
      return this.sessionsService.postStartSession(userId, startSession, true).pipe(
        map(res => {
          return UserSessionActions.startUserSessionSuccess({sessionId: res.objectId});
        }),
        catchError(error => of(UserSessionActions.startUserSessionFailure({error})))
      );
    })
  ));

  endUserSession$ = createEffect(() => this.actions$.pipe(
    ofType(UserSessionActions.endUserSession),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectUTCOffset),
      this.store$.select(AuthSelectors.selectUserSessionId),
      this.store$.select(AuthSelectors.selectCloseTabOnLogout)
    ]),
    switchMap(([action, userId, utcOffset, sessionId, closeTabOnLogout]) => {

      const previousSession: EndSession = JSON.parse(window.localStorage.getItem(GlobalConstants.UserSession));

      let endSession: EndSession;
      if (sessionId) {
        const now = moment().utcOffset(utcOffset || 0, false); //get the utcOffset fixed date
        const endTime = now.toISOString();

        endSession = {
          userId,
          endTime: endTime,
          sessionId: sessionId,
          offlineSessionId: sessionId,
          mustLogout: action.logout,
          isOffline: false
        };

        // Saving the session into local storage so if the session is failed to save on API, we will retry it on next login
        window.localStorage.setItem(GlobalConstants.UserSession, JSON.stringify(endSession));
      } else if (previousSession?.sessionId) {
        endSession = {...previousSession, mustLogout: false};
      } else {
        endSession = null
      }

      const observable = endSession ? this.sessionsService.postEndSession(endSession.userId, endSession, true) : of({});
      return observable.pipe(
        map(_ => {
          // Clear session object from local storage if its same
          const session: EndSession =  JSON.parse(window.localStorage.getItem(GlobalConstants.UserSession));

          if (endSession && endSession?.sessionId === session?.sessionId) {
            window.localStorage.removeItem(GlobalConstants.UserSession);
          }

          return UserSessionActions.endUserSessionSuccess();
        }),
        catchError(error => {
          return of(UserSessionActions.endUserSessionFailure({error}));
        }),
        finalize(() => {
          if (action.logout) {
            const authenticationMethod = window.sessionStorage.getItem(GlobalConstants.AuthenticationMethod);
            window.sessionStorage.clear();
            // This is being used in event hub service
            this.appStateService.set(AppStateConstants.online, false);
            // If this is triggered by logout then call logout success which will clear out the ngrx state in meta reducer
            this.store$.dispatch(AuthActions.logoutSuccess());

            // Check if App update is available and trigger update to bring app to latest version
            if (this.appStateService.UpdateReady) {
              this.appStateService.triggerUpdate();
            }

            // Close browser tab if user logged in via sso providers like classlink or clever
            if (authenticationMethod === 'classLink' || authenticationMethod === 'clever' || closeTabOnLogout) {
              window.close();
            }
          }
        })
      );
    })
  ));

  logScreenSession$ = createEffect(() => this.actions$.pipe(
    ofType(ScreenSessionActions.logScreenSession),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectCurrentUserGroupId),
      this.store$.select(AuthSelectors.selectUTCOffset),
      this.store$.select(AuthSelectors.selectScreenSession)
    ]),
    switchMap(([action, userId, userGroupId, utcOffset, screenSession]) => {
      const startTime = moment().utcOffset(utcOffset, false); //get the utcOffset fixed date
      const inactivityTimeoutInSeconds = 300;       // @TODO: Replace with actual inactivity timeout from system conf.
      const expirationTime = moment().add(inactivityTimeoutInSeconds, 's');

      const postData: ScreenSessionResponse = {
        userGroupId: userGroupId,
        screenType: action.screenType,
        startTime: moment(startTime).utc(),
        expirationTime: expirationTime.utc(),
      };

      if (screenSession?.screenType === action.screenType) {
        return from(this.screenSessionService.updateScreenSession(userId, screenSession.screenSessionId, postData.expirationTime)).pipe(
          map(res => ScreenSessionActions.logScreenSessionSuccess({data: {...postData, screenSessionId: res.objectId}})),
          catchError(error => of(ScreenSessionActions.logScreenSessionFailure({error})))
        );
      } else {
        return from(this.screenSessionService.postScreenSession(userId, postData)).pipe(
          map(res => ScreenSessionActions.logScreenSessionSuccess({data: {...postData, screenSessionId: res.objectId}})),
          catchError(error => of(ScreenSessionActions.logScreenSessionFailure({error})))
        );
      }
    })
  ));

  checkTokenValidation$ = createEffect(() => this.actions$.pipe(
    ofType(CheckTokenActions.checkTokenValidation),
    switchMap(action => this.authService.getUserInfo(action.token).pipe(
      map((data) => {
        // Store MP token to perform async validations
        SessionStorageService.Save(GlobalConstants.MPToken, action.token);

        return CheckTokenActions.checkTokenValidationSuccess({user: data});
      }),
      catchError(error => of(CheckTokenActions.checkTokenValidationFailure({error})))
    ))
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ResetPasswordActions.resetPassword),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectTokenValidatedUser)),
    switchMap(([action, user]) => this.authService.resetPasswordWithToken(user.userId, action.newPassword, action.token).pipe(
      map(() => ResetPasswordActions.resetPasswordSuccess()),
      catchError(error => of(ResetPasswordActions.resetPasswordFailure({error})))
    ))
  ));

  setupAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SetupAccountActions.setupAccount),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectTokenValidatedUser)),
    switchMap(([action, user]) =>
      forkJoin([
        this.authService.confirmUserAccountWithToken(user.userId, action.request, action.token),
        this.authService.postEulaAccepted(user.userId, action.token)
      ]).pipe(
        map(() => SetupAccountActions.setupAccountSuccess()),
        catchError(error => of(SetupAccountActions.setupAccountFailure({error})))
      ))
  ));

  resendSetupAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SetupAccountActions.resendSetupAccount),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectTokenValidatedUser)),
    switchMap(([action, user]) => this.authService.sendConfirmation(action.email).pipe(
      map(() => SetupAccountActions.resendSetupAccountSuccess()),
      catchError(error => of(SetupAccountActions.resendSetupAccountFailure({error})))
    ))
  ));

  signupAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SignupAccountActions.signupAccount),
    switchMap(action => this.authService.signupTeacher(action.email, action.schoolId).pipe(
      map(res => SignupAccountActions.signupAccountSuccess()),
      catchError(error => of(SignupAccountActions.signupAccountFailure({error})))
    ))
  ));

  loadAPIVersion$ = createEffect(() => this.actions$.pipe(
    ofType(ApiVersionActions.loadAPIVersion),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectApiVersionLoaded),
      this.store$.select(AuthSelectors.selectApiVersion),
    ]),
    switchMap(([_, apiVersionLoaded, apiVersion]) => {
      if (apiVersionLoaded) {
        return of(apiVersion);
      }

      return this.environmentService.getApiVersion()
        .pipe(
          map((res) => res.version),
        );
    }),
    map((res) => ApiVersionActions.loadAPIVersionSuccess({ data: res })),
    catchError((error, caught) => merge(caught, of(ApiVersionActions.loadAPIVersionFailure({ error })))),
  ));

  getUserTypeByRole(role: string) {
    if (role === 'superadmin') {
      return UserType.MPSuperAdmin;
    } else if (role === 'teacher' ||
      role === 'administrator' ||
      role === 'districtadministrator' ||
      role === 'programmanager') {
      return UserType.Teacher;
    } else {
      return UserType.Student;
    }
  }

  getAdminTypeByRole(role: string) {
    if (role === 'administrator') {
      return AdminType.SchoolAdmin;
    }
    if (role === 'districtadministrator') {
      return AdminType.DistrictAdmin;
    }
    if (role === 'programmanager') {
      return AdminType.DistrictAdmin;
    }
    return AdminType.Unknown;
  }

  constructor(private actions$: Actions,
              private store$: Store,
              private authService: AuthService,
              private msalService: MsalService,
              private classLinkService: ClasslinkService,
              private cleverService: CleverService,
              private gg4lAuthService: Gg4lAuthService,
              private googleAuthService: GoogleAuthService,
              private classesService: ClassesService,
              private nycidpService: NycidpService,
              private schoolsService: SchoolsService,
              private studentService: StudentService,
              private teacherService: TeacherService,
              private appStateService: AppStateService,
              private sessionsService: SessionsService,
              private deviceService: DeviceService,
              @Inject(ENV_CONFIG) private environmentConfig: EnvironmentConfig,
              private screenSessionService: ScreenSessionService,
              private userService: UserService,
              private academicYearService: AcademicYearService,
              private environmentService: EnvironmentService,
  ) {
  }
}
