export const environment = {
  production: false,
  idsApiUrl: 'https://identity-dev.mindplay.com/',
  lsApiUrl: 'https://reader.lightsailed.com/',
  mpApiUrl: 'https://mpapiwa002-dev.azurewebsites.net/',
  mpCoachUrlStudent: 'https://read-dev.mindplay.com/mvrc/default.aspx',
  mpCoachUrlNonStudent: '',
  mpAccountUrl: 'https://account-dev.mindplay.com/login',
  mpManagerUrl: 'https://manager-dev.mindplay.com/account/login',
  lsV10Url: 'https://lightsailed.com/school/literacy',
  cleverClientId: '665a46d36204a8d83c43',
  classLinkClientId: 'c1536154019710ee499dfbda6d1a84c98ec4fe27e4f0f2',
  gg4lClientId: 'PTRAPOWSUM',
  googleClientId: '688502325146-q9kskhd9mn136r4dbcjasoth7q5h1eqk.apps.googleusercontent.com',
  microsoftSSOClientId: 'b32c61df-1250-41c4-a86e-8315b8381933',
  version: '20250214.3',
  applicationInsightsConnectionString: '',
  environment: 'dev'
};
